import RouterPage from "./router/RouterPage";
function App() {
	return (
		<div className="">
			<RouterPage />
		</div>
	);
}

export default App;
