import React from "react";
import Layout from "../../layout/Layout";
import { Logo } from "../../assets/export/Export";
import { Link } from "react-router-dom";
import { FaTwitter, FaTelegramPlane } from "react-icons/fa";

function Navbar() {
	return (
		<div className="z-[10000000] fixed top-0 lg:top-8 w-full flex items-center justify-center lg:px-4">
			<div className="w-full max-w-screen-xl px-4 py-[15px] lg:py-6 bg-white bg-opacity-45 lg:rounded-[20px] border border-white backdrop-blur-[21px] ">
				<div className="flex items-center justify-between">
					<Link to="/">
						<Logo width={173} height={33} />
					</Link>

					<div className="hidden lg:flex">
						<ul className="flex w-full space-x-4">
							{navigation.map((nav, index) => {
								return (
									<li className="uppercase text-sm font-bold leading-tight">
										<a href={nav.url}>{nav.name}</a>
									</li>
								);
							})}
						</ul>
					</div>
					<div className="flex items-center gap-3">
						<a href="https://app.dodgecoin20.online">
							<button className="w-[113px] h-10 px-[21px] py-2.5 rounded-lg border border-neutral-900 justify-start items-center inline-flex">
								<span className="text-zinc-800 text-sm font-bold font-['Nunito Sans'] uppercase leading-tight tracking-tight">
									Buy Now
								</span>
							</button>
						</a>

						{socials.map((item, index) => {
							return (
								<div className="w-8 h-8 flex-col border border-black rounded-full justify-center items-center inline-flex">
									<a href={item.url} target="_blank" rel="noreferrer">
										{item.icon}
									</a>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Navbar;

const navigation = [
	{
		name: "staking",
		url: "https://app.dodgecoin20.online",
	},
	{
		name: "about",
		url: "https://app.dodgecoin20.online",
	},
	{
		name: "whitepaper",
		url: "https://app.dodgecoin20.online",
	},
	{
		name: "audit",
		url: "https://app.dodgecoin20.online",
	},
];

const socials = [
	{
		icon: <FaTwitter />,
		url: "https://twitter.com/DOGE_COIN20",
	},
	{
		icon: <FaTelegramPlane />,
		url: "https://t.me/DOGE_COIN20",
	},
];
