import React from "react";
import "../../../appstyle.css";

function Featured() {
	return (
		<div>
			<section
				_ngcontent-ng-c1087528584=""
				id="feature"
				className="feature-wrapper position-relative">
				<div _ngcontent-ng-c1087528584="" className="container">
					<h2
						_ngcontent-ng-c1087528584=""
						translate=""
						className="section_title text-center text-capitalize mb-4 mb-lg-5 px-3 text-uppercase">
						Featured In
					</h2>
					<div
						_ngcontent-ng-c1087528584=""
						className="row justify-content-center d-none d-lg-flex">
						<div
							_ngcontent-ng-c1087528584=""
							className="col-lg-3 text-center mb-4 ng-star-inserted">
							<img
								_ngcontent-ng-c1087528584=""
								alt=""
								className="img-fluid mx-auto featured-img p-2"
								src="https://dogecoin20.io/assets/images/featured/feature1.svg"
							/>
						</div>
						<div
							_ngcontent-ng-c1087528584=""
							className="col-lg-3 text-center mb-4 ng-star-inserted">
							<img
								_ngcontent-ng-c1087528584=""
								alt=""
								className="img-fluid mx-auto featured-img p-2"
								src="https://dogecoin20.io/assets/images/featured/feature2.svg"
							/>
						</div>
						<div
							_ngcontent-ng-c1087528584=""
							className="col-lg-3 text-center mb-4 ng-star-inserted">
							<img
								_ngcontent-ng-c1087528584=""
								alt=""
								className="img-fluid mx-auto featured-img p-2"
								src="https://dogecoin20.io/assets/images/featured/feature3.svg"
							/>
						</div>
						<div
							_ngcontent-ng-c1087528584=""
							className="col-lg-3 text-center mb-4 ng-star-inserted">
							<img
								_ngcontent-ng-c1087528584=""
								alt=""
								className="img-fluid mx-auto featured-img p-2"
								src="https://dogecoin20.io/assets/images/featured/feature4.svg"
							/>
						</div>
						<div
							_ngcontent-ng-c1087528584=""
							className="col-lg-3 text-center mb-4 ng-star-inserted">
							<img
								_ngcontent-ng-c1087528584=""
								alt=""
								className="img-fluid mx-auto featured-img p-2"
								src="https://dogecoin20.io/assets/images/featured/feature5.svg"
							/>
						</div>
						<div
							_ngcontent-ng-c1087528584=""
							className="col-lg-3 text-center mb-4 ng-star-inserted">
							<img
								_ngcontent-ng-c1087528584=""
								alt=""
								className="img-fluid mx-auto featured-img p-2"
								src="https://dogecoin20.io/assets/images/featured/feature6.svg"
							/>
						</div>
						<div
							_ngcontent-ng-c1087528584=""
							className="col-lg-3 text-center mb-4 ng-star-inserted">
							<img
								_ngcontent-ng-c1087528584=""
								alt=""
								className="img-fluid mx-auto featured-img p-2"
								src="https://dogecoin20.io/assets/images/featured/feature7.svg"
							/>
						</div>
						{/**/}
					</div>
					<div
						_ngcontent-ng-c1087528584=""
						className="feature py-0 d-block d-lg-none">
						<owl-carousel-o
							_ngcontent-ng-c1087528584=""
							className="d-flex"
							_nghost-ng-c3098439908="">
							<div
								_ngcontent-ng-c3098439908=""
								className="owl-carousel owl-theme owl-loaded owl-responsive owl-drag">
								<div
									_ngcontent-ng-c3098439908=""
									className="owl-stage-outer ng-star-inserted">
									<owl-stage
										_ngcontent-ng-c3098439908=""
										className="ng-tns-c556756694-0 ng-star-inserted">
										<div className="ng-tns-c556756694-0">
											<div
												className="owl-stage ng-tns-c556756694-0"
												style={{
													width: 190,
													transform: "translate3d(-70px, 0px, 0px)",
													transition: "all 0.25s ease 0s",
												}}>
												<div
													className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted"
													style={{ width: 0, marginRight: 10 }}>
													<img
														_ngcontent-ng-c1087528584=""
														alt=""
														className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
														src="https://dogecoin20.io/assets/images/featured/feature1.svg"
														style={{}}
													/>
													{/**/}
													{/**/}
												</div>
												{/**/}
												<div
													className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted"
													style={{ width: 0, marginRight: 10 }}>
													<img
														_ngcontent-ng-c1087528584=""
														alt=""
														className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
														src="https://dogecoin20.io/assets/images/featured/feature2.svg"
														style={{}}
													/>
													{/**/}
													{/**/}
												</div>
												{/**/}
												<div
													className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted"
													style={{ width: 0, marginRight: 10 }}>
													<img
														_ngcontent-ng-c1087528584=""
														alt=""
														className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
														src="https://dogecoin20.io/assets/images/featured/feature3.svg"
														style={{}}
													/>
													{/**/}
													{/**/}
												</div>
												{/**/}
												<div
													className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted"
													style={{ width: 0, marginRight: 10 }}>
													<img
														_ngcontent-ng-c1087528584=""
														alt=""
														className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
														src="https://dogecoin20.io/assets/images/featured/feature4.svg"
														style={{}}
													/>
													{/**/}
													{/**/}
												</div>
												{/**/}
												<div
													className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted"
													style={{ width: 0, marginRight: 10 }}>
													<img
														_ngcontent-ng-c1087528584=""
														alt=""
														className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
														src="https://dogecoin20.io/assets/images/featured/feature5.svg"
														style={{}}
													/>
													{/**/}
													{/**/}
												</div>
												{/**/}
												<div
													className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted"
													style={{ width: 0, marginRight: 10 }}>
													<img
														_ngcontent-ng-c1087528584=""
														alt=""
														className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
														src="https://dogecoin20.io/assets/images/featured/feature6.svg"
														style={{}}
													/>
													{/**/}
													{/**/}
												</div>
												{/**/}
												<div
													className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight active center ng-star-inserted"
													style={{ width: 0, marginRight: 10 }}>
													<img
														_ngcontent-ng-c1087528584=""
														alt=""
														className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
														src="https://dogecoin20.io/assets/images/featured/feature7.svg"
														style={{}}
													/>
													{/**/}
													{/**/}
												</div>
												{/**/}
												{/**/}
											</div>
										</div>
									</owl-stage>
								</div>
								{/**/}
								<div
									_ngcontent-ng-c3098439908=""
									className="owl-nav disabled ng-star-inserted">
									<div _ngcontent-ng-c3098439908="" className="owl-prev">
										prev
									</div>
									<div _ngcontent-ng-c3098439908="" className="owl-next">
										next
									</div>
								</div>
								<div
									_ngcontent-ng-c3098439908=""
									className="owl-dots disabled ng-star-inserted">
									{/**/}
								</div>
								{/**/}
								{/**/}
							</div>
						</owl-carousel-o>
					</div>
				</div>
				<img
					_ngcontent-ng-c1087528584=""
					src="https://dogecoin20.io/assets/images/svg-icons/feature-top.svg"
					className="top ng-star-inserted absolute -top-10"
				/>
				{/**/}
			</section>
		</div>
	);
}

export default Featured;
