import React from "react";
import Hero from "../components/pages/landing/Hero";
import Rewarding from "../components/pages/landing/Rewarding";
import Featured from "../components/pages/landing/featured";
import Roadmap from "../components/pages/landing/Roadmap";
import "../appstyle.css";
import Tokonomics from "../components/pages/landing/Tokonomics";
import Calculator from "../components/pages/landing/Calculator";
import Footer from "../components/pages/landing/Footer";

function Landing() {
	return (
		<div className="space-y-32 overflow-x-hidden">
			<Hero />
			<Rewarding />
			<Featured />
			<Roadmap />
			<Tokonomics />
			<Calculator />
			<Footer />
		</div>
	);
}

export default Landing;
