import React from "react";
import Layout from "../../../layout/Layout";

function Calculator() {
	return (
		<Layout>
			<div>
				{/* Heading  */}
				<h2 className="hidden lg:block text-center text-neutral-900 text-5xl font-extrabold font-['Nunito Sans'] uppercase leading-[57.60px] tracking-wide">
					Rewards Calculator
				</h2>

				{/* MOBILE HEAD  */}
				<h2 className="lg:hidden text-center text-neutral-900 text-3xl font-extrabold font-['Nunito Sans'] uppercase leading-9 tracking-wide">
					Rewards Calculator
				</h2>
				<p className="text-center text-zinc-500 text-sm lg:text-lg font-normal font-['Nunito Sans'] leading-relaxed">
					Dogecoin20 offers a new passive rewards opportunity to the meme coin
					scene but could also benefit from token price appreciation. DOGE20
					<br />
					has a fully diluted starting market cap nearly 900 times cheaper than
					the original Dogecoin.
				</p>
				{/* END OF HEADING  */}
			</div>

			<div className="py-10 flex gap-10 flex-col-reverse lg:flex-row items-center">
				<div>
					<h3 className="text-neutral-900 text-3xl lg:text-[40px] font-extrabold">
						READY TO BUY?
					</h3>
					<p className="text-sm lg:text-base text-gray-500 lg:w-[70%]">
						All you need to buy DOGE20 tokens at the lowest presale price is a
						decentralised wallet containing ETH, BNB, or USDT. Simply connect to
						the widget above to swap tokens!
					</p>
					<div className="w-1/2 lg:w-1/3">
						<a
							_ngcontent-ng-c1116280342=""
							href="https://app.dodgecoin20.online"
							target="_blank"
							type="button"
							className="btn btn-primary text-dark me-auto mb-3 me-md-3 mb-md-0">
							{" "}
							Buy Now &nbsp;
							<svg-icon
								_ngcontent-ng-c1116280342=""
								src="./assets/images/svg-icons/angle-right.svg"
								className="ms-2">
								<svg
									width={25}
									height={24}
									viewBox="0 0 25 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									_ngcontent-ng-c1116280342=""
									aria-hidden="true"
									style={{ width: 20, height: 20 }}>
									<rect
										x="0.5"
										width={24}
										height={24}
										rx={12}
										fill="#FF9709"
										fillOpacity="0.3"
										_ngcontent-ng-c1116280342=""
									/>
									<path
										d="M14.1196 7.95312L18.1663 11.9998L14.1196 16.0465"
										stroke="#171717"
										strokeMiterlimit={10}
										strokeLinecap="round"
										strokeLinejoin="round"
										_ngcontent-ng-c1116280342=""
										fill="none"
									/>
									<path
										d="M6.8335 12H18.0535"
										stroke="#171717"
										strokeMiterlimit={10}
										strokeLinecap="round"
										strokeLinejoin="round"
										_ngcontent-ng-c1116280342=""
										fill="none"
									/>
								</svg>
							</svg-icon>
						</a>
					</div>
				</div>
				<div className="hidden relative w-full p-4 space-y-5 h-[367.55px] bg-white rounded-[20px] border-4 border-purple-600"></div>
			</div>
		</Layout>
	);
}

export default Calculator;
