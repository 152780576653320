import React from "react";

function Roadmap() {
	return (
		<div>
			<h2
				_ngcontent-ng-c2070180045=""
				translate=""
				class="section_title text-center">
				Roadmap
			</h2>
			<img
				_ngcontent-ng-c2070180045=""
				src="https://dogecoin20.io/assets/images/svg-icons/roadmap-line.svg"
				alt=""
				class="d-flex justify-content-center mx-auto line"></img>

			{/* NEW SECTION SIDE  */}
			<div className="w-full h-[660px] mx-auto py-[180px] justify-center items-center hidden lg:inline-flex">
				{/* <div className="w-full h-[5px] left-0 top-[327.50px] absolute bg-gradient-to-r from-amber-300 to-white" /> */}
				<div className="pl-[67.06px] pr-3 justify-center items-start flex">
					<div className="w-[220.16px] h-[300px] relative">
						<div className="w-0.5 h-20 left-[48px] top-[42px] absolute bg-gradient-to-b from-neutral-600 to-white" />
						<div className="w-10 h-10 px-[15.20px] pt-[8.35px] pb-[9.25px] left-[30px] top-[130px] absolute bg-purple-600 rounded-[40px] shadow justify-center items-center inline-flex">
							<div className="text-white text-base font-black font-['Nunito Sans'] leading-snug">
								1
							</div>
						</div>
						<div className="h-[197.27px] p-[15px] left-[-50px] top-[-150px] absolute rounded-[5px] flex-col justify-start items-start inline-flex">
							<div className="self-stretch h-[49px] pr-16 pb-2.5 flex-col justify-start items-start flex">
								<div className="text-neutral-900 text-base font-black font-['Nunito Sans'] leading-tight">
									Presale and
									<br />
									Marketing
								</div>
							</div>
							<div className="self-stretch h-[118.28px] pr-[0.72px] pb-[0.28px] flex-col justify-start items-start flex">
								<div className="text-zinc-500 text-sm font-normal font-['Nunito Sans'] leading-tight">
									Dogecoin20 starts on a level
									<br />
									playing field with a fair
									<br />
									presale. The team will
									<br />
									allocate a portion of funds
									<br />
									raised towards a professional
									<br />
									marketing strategy.
								</div>
							</div>
						</div>
					</div>
					<div className="w-[220.16px] h-[300px] relative">
						<div className="w-0.5 h-20 left-[48px] top-[178px] absolute bg-gradient-to-b from-neutral-600 to-white" />
						<div className="w-10 h-10 pl-[15.19px] pr-[15.20px] pt-[8.35px] pb-[9.25px] left-[30px] top-[130px] absolute bg-purple-600 rounded-[40px] shadow justify-center items-center inline-flex">
							<div className="text-white text-base font-black font-['Nunito Sans'] leading-snug">
								2
							</div>
						</div>
						<div className="h-[197.27px] p-[15px] left-[-50px] top-[270px] absolute rounded-[5px] flex-col justify-start items-start inline-flex">
							<div className="self-stretch h-[49px] pr-[50.92px] pb-2.5 flex-col justify-start items-start flex">
								<div className="text-neutral-900 text-base font-black font-['Nunito Sans'] leading-tight">
									Early Staking
									<br />
									Incentive
								</div>
							</div>
							<div className="self-stretch h-[118.28px] pr-1.5 pb-[0.28px] flex-col justify-start items-start flex">
								<div className="text-zinc-500 text-sm font-normal font-['Nunito Sans'] leading-tight">
									Presale buyers of DOGE20
									<br />
									will be able to stake their
									<br />
									tokens into the smart
									<br />
									contract before listing day to
									<br />
									benefit from the high early
									<br />
									rewards opportunity.
								</div>
							</div>
						</div>
					</div>
					<div className="w-[220.16px] h-[300px] relative">
						<div className="w-0.5 h-20 left-[48px] top-[42px] absolute bg-gradient-to-b from-neutral-600 to-white" />
						<div className="w-10 h-10 pl-[15.19px] pr-[15.21px] pt-[8.35px] pb-[9.25px] left-[30px] top-[130px] absolute bg-purple-600 rounded-[40px] shadow justify-center items-center inline-flex">
							<div className="text-white text-base font-black font-['Nunito Sans'] leading-snug">
								3
							</div>
						</div>
						<div className="h-[217.27px] p-[15px] left-[-50px] top-[-150px] absolute rounded-[5px] flex-col justify-start items-start inline-flex">
							<div className="self-stretch h-[49px] pr-[46.29px] pb-2.5 flex-col justify-start items-start flex">
								<div className="text-neutral-900 text-base font-black font-['Nunito Sans'] leading-tight">
									Presale Token
									<br />
									Claiming
								</div>
							</div>
							<div className="self-stretch h-[138.27px] pr-[11.78px] pb-[0.28px] flex-col justify-start items-start flex">
								<div className="text-zinc-500 text-sm font-normal font-['Nunito Sans'] leading-tight">
									The DOGE20 staking smart
									<br />
									contract is programmed to
									<br />
									distribute tokens to all
									<br />
									stakers in the pool over a<br />
									period of two years,
									<br />
									providing sustained passive
									<br />
									rewards.
								</div>
							</div>
						</div>
					</div>
					<div className="w-[220.16px] h-[300px] relative">
						<div className="w-0.5 h-20 left-[48px] top-[178px] absolute bg-gradient-to-b from-neutral-600 to-white" />
						<div className="w-10 h-10 px-[15.20px] pt-[8.35px] pb-[9.25px] left-[30px] top-[130px] absolute bg-purple-600 rounded-[40px] shadow justify-center items-center inline-flex">
							<div className="text-white text-base font-black font-['Nunito Sans'] leading-snug">
								4
							</div>
						</div>
						<div className="h-[197.27px] p-[15px] left-[-50px] top-[270px] absolute rounded-[5px] flex-col justify-start items-start inline-flex">
							<div className="self-stretch h-[49px] pr-[37.77px] pb-2.5 flex-col justify-start items-start flex">
								<div className="text-neutral-900 text-base font-black font-['Nunito Sans'] leading-tight">
									DEX Launch on
									<br />
									Uniswap
								</div>
							</div>
							<div className="self-stretch h-[118.28px] pr-[4.25px] pb-[0.28px] flex-col justify-start items-start flex">
								<div className="text-zinc-500 text-sm font-normal font-['Nunito Sans'] leading-tight">
									DOGE20 will launch on
									<br />
									Uniswap, the largest
									<br />
									decentralized exchange. This
									<br />
									will provide the best trading
									<br />
									environment and early
									<br />
									liquidity.
								</div>
							</div>
						</div>
					</div>
					<div className="w-[220.16px] h-[300px] relative">
						<div className="w-0.5 h-20 left-[48px] top-[42px] absolute bg-gradient-to-b from-neutral-600 to-white" />
						<div className="w-10 h-10 pl-[15.19px] pr-[15.21px] pt-[8.35px] pb-[9.25px] left-[30px] top-[130px] absolute bg-purple-600 rounded-[40px] shadow justify-center items-center inline-flex">
							<div className="text-white text-base font-black font-['Nunito Sans'] leading-snug">
								5
							</div>
						</div>
						<div className="h-[197.27px] p-[15px] left-[-50px] top-[-150px] absolute rounded-[5px] flex-col justify-start items-start inline-flex">
							<div className="self-stretch h-[49px] pr-[7.25px] pb-2.5 flex-col justify-start items-start flex">
								<div className="text-neutral-900 text-base font-black font-['Nunito Sans'] leading-tight">
									Allocated Liquidity
									<br />
									Provision
								</div>
							</div>
							<div className="self-stretch h-[118.28px] pr-[19.43px] pb-[0.28px] flex-col justify-start items-start flex">
								<div className="text-zinc-500 text-sm font-normal font-['Nunito Sans'] leading-tight">
									The Dogecoin20 team will
									<br />
									add a substantial liquidity
									<br />
									pool to Uniswap. For this
									<br />
									purpose, 10% of the total
									<br />
									token supply has been set
									<br />
									aside.
								</div>
							</div>
						</div>
					</div>
					<div className="w-[220.16px] h-[300px] relative">
						<div className="w-0.5 h-20 left-[48px] top-[178px] absolute bg-gradient-to-b from-neutral-600 to-white" />
						<div className="w-10 h-10 px-[15.20px] pt-[8.35px] pb-[9.25px] left-[30px] top-[130px] absolute bg-purple-600 rounded-[40px] shadow justify-center items-center inline-flex">
							<div className="text-white text-base font-black font-['Nunito Sans'] leading-snug">
								6
							</div>
						</div>
						<div className="h-[217.27px] p-[15px] left-[-50px] top-[270px] absolute rounded-[5px] flex-col justify-start items-start inline-flex">
							<div className="self-stretch h-[49px] pr-[8.89px] pb-2.5 flex-col justify-start items-start flex">
								<div className="text-neutral-900 text-base font-black font-['Nunito Sans'] leading-tight">
									Long-Term Passive
									<br />
									Rewards
								</div>
							</div>
							<div className="self-stretch h-[138.27px] pr-[11.77px] pb-[0.28px] flex-col justify-start items-start flex">
								<div className="text-zinc-500 text-sm font-normal font-['Nunito Sans'] leading-tight">
									The DOGE20 staking smart
									<br />
									contract is programmed to
									<br />
									distribute tokens to all
									<br />
									stakers in the pool over a<br />
									period of two years,
									<br />
									providing sustained passive
									<br />
									rewards.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* END OF DESKTOP PART  */}

			{/* START OF MOBILE VIEW  */}

			<div className="w-[390px] h-[868.48px] flex-col justify-start items-start gap-[49.20px] inline-flex lg:hidden">
				{/* <div className="w-0.5 h-[774.21px] left-[29px] top-[0.80px]  bg-gradient-to-b from-amber-300 to-white" /> */}
				<div className="relative self-stretch h-[107.08px] pl-20 pr-5 flex-col justify-start items-start flex">
					<div className="w-[25px] h-[25px] px-[9.11px] pt-[3.19px] pb-[5.01px] left-[18px] top-[0.80px] absolute bg-purple-600 rounded-[25px] shadow justify-center items-center inline-flex">
						<div className="text-white text-xs font-normal font-['Ubuntu'] leading-none">
							1
						</div>
					</div>

					<div className="self-stretch h-[107.08px] flex-col justify-start items-start gap-[5.79px] flex">
						<div className="text-neutral-900 text-lg font-black font-['Nunito Sans'] leading-snug">
							Presale and Marketing
						</div>
						<div className="self-stretch h-[79.29px] pr-0.5 pb-[0.29px] flex-col justify-start items-start flex">
							<div className="text-zinc-500 text-sm font-normal font-['Nunito Sans'] leading-tight">
								Dogecoin20 starts on a level playing field
								<br />
								with a fair presale. The team will allocate a<br />
								portion of funds raised towards a professional
								<br />
								marketing strategy.
							</div>
						</div>
					</div>
				</div>
				<div className="relative self-stretch h-[107.08px] pl-20 pr-5 flex-col justify-start items-start flex">
					<div className="w-[25px] h-[25px] px-[9.11px] pt-[3.19px] pb-[5.01px] left-[18px] top-[0.80px] absolute bg-purple-600 rounded-[25px] shadow justify-center items-center inline-flex">
						<div className="text-white text-xs font-normal font-['Ubuntu'] leading-none">
							2
						</div>
					</div>
					<div className="self-stretch h-[107.08px] flex-col justify-start items-start gap-[5.79px] flex">
						<div className="text-neutral-900 text-lg font-black font-['Nunito Sans'] leading-snug">
							Early Staking Incentive
						</div>
						<div className="self-stretch h-[79.29px] pr-[28.55px] pb-[0.29px] flex-col justify-start items-start flex">
							<div className="text-zinc-500 text-sm font-normal font-['Nunito Sans'] leading-tight">
								Presale buyers of DOGE20 will be able to
								<br />
								stake their tokens into the smart contract
								<br />
								before listing day to benefit from the high
								<br />
								early rewards opportunity.
							</div>
						</div>
					</div>
				</div>
				<div className="relative self-stretch h-[107.08px] pl-20 pr-5 flex-col justify-start items-start flex">
					<div className="w-[25px] h-[25px] px-[9.11px] pt-[3.19px] pb-[5.01px] left-[18px] top-[0.80px] absolute bg-purple-600 rounded-[25px] shadow justify-center items-center inline-flex">
						<div className="text-white text-xs font-normal font-['Ubuntu'] leading-none">
							3
						</div>
					</div>
					<div className="self-stretch h-[107.08px] flex-col justify-start items-start gap-[5.79px] flex">
						<div className="text-neutral-900 text-lg font-black font-['Nunito Sans'] leading-snug">
							Presale Token Claiming
						</div>
						<div className="self-stretch h-[79.29px] pr-[7.58px] pb-[0.29px] flex-col justify-start items-start flex">
							<div className="text-zinc-500 text-sm font-normal font-['Nunito Sans'] leading-tight">
								To ensure a fair launch for DOGE20, presale
								<br />
								buyers will be able to claim their tokens from
								<br />
								the website at the same time as the Uniswap
								<br />
								listing.
							</div>
						</div>
					</div>
				</div>
				<div className="relative self-stretch h-[87.08px] pl-20 pr-5 flex-col justify-start items-start flex">
					<div className="w-[25px] h-[25px] px-[9.11px] pt-[3.20px] pb-[5px] left-[18px] top-[0.80px] absolute bg-purple-600 rounded-[25px] shadow justify-center items-center inline-flex">
						<div className="text-white text-xs font-normal font-['Ubuntu'] leading-none">
							4
						</div>
					</div>
					<div className="self-stretch h-[87.08px] flex-col justify-start items-start gap-[5.80px] flex">
						<div className="text-neutral-900 text-lg font-black font-['Nunito Sans'] leading-snug">
							DEX Launch on Uniswap
						</div>
						<div className="self-stretch h-[59.28px] pr-2 pb-[0.28px] flex-col justify-start items-start flex">
							<div className="text-zinc-500 text-sm font-normal font-['Nunito Sans'] leading-tight">
								DOGE20 will launch on Uniswap, the largest
								<br />
								decentralized exchange. This will provide the
								<br />
								best trading environment and early liquidity.
							</div>
						</div>
					</div>
				</div>
				<div className="relative self-stretch h-[107.08px] pl-20 pr-5 flex-col justify-start items-start flex">
					<div className="w-[25px] h-[25px] px-[9.11px] pt-[3.19px] pb-[5.01px] left-[18px] top-[0.80px] absolute bg-purple-600 rounded-[25px] shadow justify-center items-center inline-flex">
						<div className="text-white text-xs font-normal font-['Ubuntu'] leading-none">
							5
						</div>
					</div>
					<div className="self-stretch h-[107.08px] flex-col justify-start items-start gap-[5.79px] flex">
						<div className="text-neutral-900 text-lg font-black font-['Nunito Sans'] leading-snug">
							Allocated Liquidity Provision
						</div>
						<div className="self-stretch h-[79.29px] pr-[13.08px] pb-[0.29px] flex-col justify-start items-start flex">
							<div className="text-zinc-500 text-sm font-normal font-['Nunito Sans'] leading-tight">
								The Dogecoin20 team will add a substantial
								<br />
								liquidity pool to Uniswap. For this purpose,
								<br />
								10% of the total token supply has been set
								<br />
								aside.
							</div>
						</div>
					</div>
				</div>
				<div className="relative self-stretch h-[107.08px] pl-20 pr-5 flex-col justify-start items-start flex">
					<div className="w-[25px] h-[25px] px-[9.11px] pt-[3.19px] pb-[5.01px] left-[18px] top-[0.80px] absolute bg-purple-600 rounded-[25px] shadow justify-center items-center inline-flex">
						<div className="text-white text-xs font-normal font-['Ubuntu'] leading-none">
							6
						</div>
					</div>
					<div className="self-stretch h-[107.08px] flex-col justify-start items-start gap-[5.79px] flex">
						<div className="text-neutral-900 text-lg font-black font-['Nunito Sans'] leading-snug">
							Long-Term Passive Rewards
						</div>
						<div className="self-stretch h-[79.29px] pr-[0.42px] pb-[0.29px] flex-col justify-start items-start flex">
							<div className="text-zinc-500 text-sm font-normal font-['Nunito Sans'] leading-tight">
								The DOGE20 staking smart contract is
								<br />
								programmed to distribute tokens to all stakers
								<br />
								in the pool over a period of two years,
								<br />
								providing sustained passive rewards.
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* END OF MOBILE VIEW  */}
		</div>
	);
}

export default Roadmap;
