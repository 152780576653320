import React, { useState } from "react";
import Bg from "../../../assets/banner.png";
import Layout from "../../../layout/Layout";
import dodge from "../../../assets/dodge.svg";

function Hero() {
	const [selectedBtn, setSelectedBtn] = useState("ETH");
	const [amount, setAmount] = useState(0);

	const handleSelectBtn = (text) => {
		setSelectedBtn(text);
	};
	return (
		<>
			<div className="hidden lg:flex w-full relative">
				<img
					src={Bg}
					alt="banner"
					className="-z-1 absolute  h-[110vh] w-full object-cover"
				/>

				<div className="z-10 relative min-h-screen w-full hidden md:flex items-center justify-end pt-40 px-24">
					<div
						_ngcontent-ng-c4169389944=""
						className="col-12 col-lg-6 col-xl-5 right mt-4 mt-lg-0 bg-white p-4 max-w-[460px] rounded-xl border-4 border-purple-600">
						<div _ngcontent-ng-c4169389944="" className="walletBox">
							<div
								_ngcontent-ng-c4169389944=""
								className="w-100 d-flex flex-column align-items-center justify-content-start text-center">
								{/**/}
								<h4
									_ngcontent-ng-c4169389944=""
									translate=""
									className="text-dark text-center font-22 fw-bold ng-star-inserted">
									$DOGE20 Claim and Token now LIVE!
								</h4>
								{/**/}
								<p
									_ngcontent-ng-c4169389944=""
									translate=""
									className="text-dark font-16 fw-semibold mb-2 text-break ng-star-inserted">
									You can now claim your $DOGE20 tokens. Plus, stake your tokens
									to earn rewards! Add
									0x2541A36BE4cD39286ED61a3E6AFC2307602489d6 to your wallet to
									see your $DOGE20.
								</p>
								{/**/}
								{/**/}
								{/**/}
								<p
									_ngcontent-ng-c4169389944=""
									className="my-2 fw-semibold text-secondary">
									Over $10M raised!
								</p>
								<div
									_ngcontent-ng-c4169389944=""
									className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
									<span _ngcontent-ng-c4169389944="">
										Your purchased DOGE20 = 0
									</span>
									<img
										_ngcontent-ng-c4169389944=""
										src="https://dogecoin20.io/assets/images/svg-icons/info-icon.svg"
										alt=""
										className="img-fluid ms-2 cursor-pointer"
									/>
								</div>
								<div
									_ngcontent-ng-c4169389944=""
									className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
									<span _ngcontent-ng-c4169389944="">
										Your stakeable DOGE20 = 0
									</span>
									<img
										_ngcontent-ng-c4169389944=""
										src="https://dogecoin20.io/assets/images/svg-icons/info-icon.svg"
										alt=""
										className="img-fluid ms-2 cursor-pointer"
									/>
								</div>
							</div>
							<div
								_ngcontent-ng-c4169389944=""
								className="swapArea mt-4 ng-star-inserted">
								<div
									_ngcontent-ng-c4169389944=""
									className="d-flex align-items-center justify-content-center gap-2 mt-2 ng-star-inserted">
									<a
										href="https://app.dodgecoin20.online/"
										target="_blank"
										rel="noreferrer"
										className="w-full">
										<button
											_ngcontent-ng-c4169389944=""
											type="button"
											translate=""
											className="btn btn-primary w-100">
											$Claim DOGE20
										</button>
									</a>
								</div>
								<div
									_ngcontent-ng-c4169389944=""
									className="d-flex align-items-center justify-content-center gap-2 mt-2 ng-star-inserted">
									<a
										href="https://app.dodgecoin20.online/"
										target="_blank"
										rel="noreferrer"
										className="w-full">
										<button
											_ngcontent-ng-c4169389944=""
											type="button"
											translate=""
											className="btn btn-primary w-100">
											Connect Wallet
										</button>
									</a>
								</div>
								{/**/}
								{/**/}
								<div _ngcontent-ng-c4169389944="" className="mt-3 w-full">
									<p
										_ngcontent-ng-c4169389944=""
										className="font-12 text-dark text-center m-0">
										{/**/}
									</p>
									<div className="w-full flex items-center justify-center gap-2 mt-4">
										<p
											// _ngcontent-ng-c4169389944=""
											translate=""
											className="font-12 text-center text-purple m-0">
											Powered by{" "}
										</p>
										<img
											_ngcontent-ng-c4169389944=""
											src="https://dogecoin20.io/assets/images/svg-icons/W3P_Black.svg"
											alt=""
											className="w-[27%]"
										/>
									</div>
								</div>
							</div>
							{/**/}
							{/**/}
						</div>
					</div>
				</div>
			</div>

			<div className="md:hidden relative bg-[#FBD869] pt-0 pb-10">
				<img src={Bg} alt="banner" className="w-full h-[30vh] object-cover" />
				<Layout>
					<div
						_ngcontent-ng-c4169389944=""
						className="col-12 col-lg-6 col-xl-5 right mt-4 mt-lg-0 bg-white p-4 rounded-xl border-4 border-purple-600">
						<div _ngcontent-ng-c4169389944="" className="walletBox">
							<div
								_ngcontent-ng-c4169389944=""
								className="w-100 d-flex flex-column align-items-center justify-content-start text-center">
								{/**/}
								<h4
									_ngcontent-ng-c4169389944=""
									translate=""
									className="text-dark text-center font-22 fw-bold ng-star-inserted">
									$DOGE20 Claim and Token now LIVE!
								</h4>
								{/**/}
								<p
									_ngcontent-ng-c4169389944=""
									translate=""
									className="text-dark font-16 fw-semibold mb-2 text-break ng-star-inserted">
									You can now claim your $DOGE20 tokens. Plus, stake your tokens
									to earn rewards! Add
									0x2541A36BE4cD39286ED61a3E6AFC2307602489d6 to your wallet to
									see your $DOGE20.
								</p>
								{/**/}
								{/**/}
								{/**/}
								<p
									_ngcontent-ng-c4169389944=""
									className="my-2 fw-semibold text-secondary">
									Over $10M raised!
								</p>
								<div
									_ngcontent-ng-c4169389944=""
									className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
									<span _ngcontent-ng-c4169389944="">
										Your purchased DOGE20 = 0
									</span>
									<img
										_ngcontent-ng-c4169389944=""
										src="https://dogecoin20.io/assets/images/svg-icons/info-icon.svg"
										alt=""
										className="img-fluid ms-2 cursor-pointer"
									/>
								</div>
								<div
									_ngcontent-ng-c4169389944=""
									className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
									<span _ngcontent-ng-c4169389944="">
										Your stakeable DOGE20 = 0
									</span>
									<img
										_ngcontent-ng-c4169389944=""
										src="https://dogecoin20.io/assets/images/svg-icons/info-icon.svg"
										alt=""
										className="img-fluid ms-2 cursor-pointer"
									/>
								</div>
							</div>
							<div
								_ngcontent-ng-c4169389944=""
								className="swapArea mt-4 ng-star-inserted">
								<div
									_ngcontent-ng-c4169389944=""
									className="d-flex align-items-center justify-content-center gap-2 mt-2 ng-star-inserted">
									<a
										href="https://app.dodgecoin20.online/"
										target="_blank"
										rel="noreferrer"
										className="w-full">
										<button
											_ngcontent-ng-c4169389944=""
											type="button"
											translate=""
											className="btn btn-primary w-100">
											$Claim DOGE20
										</button>
									</a>
								</div>
								<div
									_ngcontent-ng-c4169389944=""
									className="d-flex align-items-center justify-content-center gap-2 mt-2 ng-star-inserted">
									<a
										href="https://app.dodgecoin20.online/"
										target="_blank"
										rel="noreferrer"
										className="w-full">
										<button
											_ngcontent-ng-c4169389944=""
											type="button"
											translate=""
											className="btn btn-primary w-100">
											Connect Wallet
										</button>
									</a>
								</div>
								{/**/}
								{/**/}
								<div _ngcontent-ng-c4169389944="" className="mt-3">
									<p
										_ngcontent-ng-c4169389944=""
										className="font-12 text-dark text-center m-0">
										{/**/}
									</p>
									<div className="w-full flex items-center justify-center gap-2 mt-4">
										<p
											// _ngcontent-ng-c4169389944=""
											translate=""
											className="font-12 text-center text-purple m-0">
											Powered by{" "}
										</p>
										<img
											_ngcontent-ng-c4169389944=""
											src="https://dogecoin20.io/assets/images/svg-icons/W3P_Black.svg"
											alt=""
											className="w-[27%]"
										/>
									</div>
								</div>
							</div>
							{/**/}
							{/**/}
						</div>
					</div>
				</Layout>
			</div>
		</>
	);
}

export default Hero;

function SelectButton({ item, selected, onClick }) {
	return (
		<button
			onClick={onClick}
			className={`w-[100.86px] md:w-[132.20px] pl-[17.42px] md:pl-[33.09px] pr-[17.44px] md:pr-[33.11px] py-3.5 bg-white rounded-md border justify-center items-center flex ${
				selected === item.text
					? "border-neutral-900 text-neutral-900"
					: "border-zinc-500 text-zinc-500"
			}`}>
			<div className="h-[22px] flex-col justify-start items-start inline-flex">
				<div className="w-[22px] h-[22px] flex-col justify-center items-center flex">
					<img src={item.icon} alt="" />
				</div>
			</div>
			<div className="px-2 flex-col justify-start items-center inline-flex">
				<div className="text-center text-sm font-bold font-['Nunito Sans'] uppercase leading-[14px]">
					{item.text}
				</div>
			</div>
		</button>
	);
}

<div className="w-[100.86px] pl-[17.42px] pr-[17.44px] py-3.5 bg-white rounded-md border border-neutral-900 justify-center items-center flex">
	<div className="h-[22px] flex-col justify-start items-start inline-flex">
		<div className="w-[22px] h-[22px] flex-col justify-center items-center flex">
			<div className="w-[22px] h-[22px] relative" />
		</div>
	</div>
	<div className="px-2 pb-[0.50px] flex-col justify-start items-center inline-flex">
		<div className="text-center text-neutral-900 text-sm font-bold font-['Nunito Sans'] uppercase leading-[14px]">
			ETH
		</div>
	</div>
</div>;
const btns = [
	{
		text: "ETH",
		icon: "https://dogecoin20.io/assets/images/svg-icons/ETH.svg",
	},
	{
		text: "USDT",
		icon: "https://dogecoin20.io/assets/images/svg-icons/usdt.svg",
	},
	{
		text: "FIAT",
		icon: "https://dogecoin20.io/assets/images/svg-icons/card.svg",
	},
];

function CustomInput({ suffixIcon, ...props }) {
	return (
		<div className="relative">
			<input
				className="w-full caret-black px-4 py-[12px] bg-white rounded-md border border-zinc-500 focus:outline-none"
				pattern="^[ A-Za-z0-9_@./#&+-]*$"
				inputMode="numeric"
				{...props}
			/>

			<div className="z-10 absolute inset-y-0 right-0 flex items-center pr-2">
				<div className="w-[22px] h-[22px] flex-col justify-center items-center flex">
					<img src={suffixIcon} alt="" />
				</div>
			</div>
		</div>
	);
}
