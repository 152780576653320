import React from "react";
import Layout from "../../../layout/Layout";
import twitter from "../../../assets/twitter-light.svg.svg";
import telegram from "../../../assets/telegram-light.svg.svg";

function Footer() {
	return (
		<div className="bg-neutral-900">
			<img
				_ngcontent-ng-c2644916845=""
				src="https://dogecoin20.io/assets/images/svg-icons/footer.svg"
				alt=""
				class="img-fluid w-100"
			/>
			<Layout>
				<div>
					<div className="py-10 grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-0 items-center">
						<div className="flex flex-row lg:flex-col items-center gap-3">
							<img
								src="https://dogecoin20.io/assets/images/svg-icons/token.svg"
								alt=""
								className="w-[50px] h-[50px] lg:w-[210px] lg:h-[210px] "
							/>

							<p className="pt-3 text-white text-[23.10px] font-black">
								DOGECOIN
							</p>
						</div>

						<div className="text-stone-200 grid grid-cols-1 lg:grid-cols-3">
							<ul className="space-y-4">
								{linkOne.map((items, index) => (
									<li key={index}>{items}</li>
								))}
							</ul>
							<ul className="space-y-4">
								{linkTwo.map((items, index) => (
									<li key={index}>{items}</li>
								))}
							</ul>
							<ul className="space-y-4">
								{address.map((items, index) => (
									<li key={index}>{items}</li>
								))}
							</ul>
						</div>
					</div>
					<div className="bg-neutral-900 text-stone-200 py-5 space-y-3 flex flex-col lg:items-end">
						<div className="flex items-center gap-3">
							<img src={twitter} alt="" />
							<img src={telegram} alt="" />
						</div>
						<p className="text-sm">2024 Dogecoin20. All Rights Reserved.</p>
					</div>
				</div>
			</Layout>
		</div>
	);
}

export default Footer;

const linkOne = ["ABOUT", "ROADMAP", "REWARDS", "FAQ"];
const linkTwo = ["PRIVACY POLICY", "COOKIES", "TERMS & CONDITIONS"];
const address = [
	"4th Floor",
	"Woofer Street",
	"Doge Trade Center",
	"Panama",
	"marketing@dogecoin20.io",
];
