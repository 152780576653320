import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "../pages/Landing";
import Navbar from "../components/header/Navbar";

function RouterPage() {
	return (
		<>
			<Router>
				<Navbar />
				<Routes>
					<Route path="/" element={<Landing />} />
				</Routes>
			</Router>
		</>
	);
}

export default RouterPage;
