import React from "react";
import Layout from "../../../layout/Layout";
import "../../../appstyle.css";

function Rewarding() {
	return (
		<div
			className="py-20 flex flex-col gap-20 w-full max-w-screen-xl mx-auto px-4 "
			style={{ columnGap: "50px" }}>
			<div
				_ngcontent-ng-c1116280342=""
				id="about"
				className="about-wrapper mt-sm-3">
				<div _ngcontent-ng-c1116280342="" className="container">
					<div
						_ngcontent-ng-c1116280342=""
						className="row justify-content-center">
						<div _ngcontent-ng-c1116280342="" className="col-lg-11">
							<h3
								_ngcontent-ng-c1116280342=""
								translate=""
								className="m-0 section_title text-sm-start text-md-center">
								Our Doge just got more rewarding
							</h3>
							<img
								_ngcontent-ng-c1116280342=""
								src="https://dogecoin20.io/assets/images/svg-icons/about_line.svg"
								className="d-flex line justify-content-center mx-auto"
							/>
							<p
								_ngcontent-ng-c1116280342=""
								translate=""
								className="text-secondary text-center font-16 pt-4 font-sm-18">
								Prepare for the ultimate Doge upgrade! We're sending Elon's fave
								towards a more sustainable future. Stake DOGE20 today to start
								earning eco-friendly rewards.
							</p>
							<div
								_ngcontent-ng-c1116280342=""
								className="d-flex align-items-center justify-content-center mt-4">
								<a
									_ngcontent-ng-c1116280342=""
									href="https://app.dodgecoin20.online"
									target="_blank"
									type="button"
									className="btn btn-primary text-dark me-auto mb-3 me-md-3 mb-md-0">
									{" "}
									Whitepaper{" "}
									<svg-icon
										_ngcontent-ng-c1116280342=""
										src="./assets/images/svg-icons/angle-right.svg"
										className="ms-2">
										<svg
											width={25}
											height={24}
											viewBox="0 0 25 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
											_ngcontent-ng-c1116280342=""
											aria-hidden="true"
											style={{ width: 20, height: 20 }}>
											<rect
												x="0.5"
												width={24}
												height={24}
												rx={12}
												fill="#FF9709"
												fillOpacity="0.3"
												_ngcontent-ng-c1116280342=""
											/>
											<path
												d="M14.1196 7.95312L18.1663 11.9998L14.1196 16.0465"
												stroke="#171717"
												strokeMiterlimit={10}
												strokeLinecap="round"
												strokeLinejoin="round"
												_ngcontent-ng-c1116280342=""
												fill="none"
											/>
											<path
												d="M6.8335 12H18.0535"
												stroke="#171717"
												strokeMiterlimit={10}
												strokeLinecap="round"
												strokeLinejoin="round"
												_ngcontent-ng-c1116280342=""
												fill="none"
											/>
										</svg>
									</svg-icon>
								</a>
								<a
									_ngcontent-ng-c1116280342=""
									href="https://app.dodgecoin20.online"
									target="_blank"
									type="button"
									className="btn btn-purple cursor-default me-auto mb-3 me-md-3 mb-md-0">
									{" "}
									Audit{" "}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				_ngcontent-ng-c1116280342=""
				className="row d-flex align-items-center justify-content-between content mt-4 ng-star-inserted">
				<div
					_ngcontent-ng-c1116280342=""
					className="col-12 col-md-6 d-flex justify-content-center shadow-bg position-relative">
					<img
						_ngcontent-ng-c1116280342=""
						alt=""
						className="img-fluid mxh-500"
						src="https://dogecoin20.io/assets/images/svg-icons/about1.png"
					/>
				</div>
				<div _ngcontent-ng-c1116280342="" className="col-12 col-md-6">
					<h1
						_ngcontent-ng-c1116280342=""
						translate=""
						className="section_title">
						Good Boy Staking!
					</h1>
					<p
						_ngcontent-ng-c1116280342=""
						translate=""
						className="font-16 font-sm-18 pt-2 pb-2 text-secondary">
						{" "}
						Utilising smart contracts powered by Ethereum, DOGE20 is much more
						than a meme coin and brings passive earning potential to the
						community. Harness the power of your DOGE20 tokens by staking and
						earning rewards, making your tokens work for you.{" "}
					</p>
					<div
						_ngcontent-ng-c1116280342=""
						className="d-flex align-items-center justify-content-center justify-content-lg-start">
						<a href="https://app.dodgecoin20.online">
							<button
								_ngcontent-ng-c1116280342=""
								type="button"
								className="btn btn-primary text-dark me-sm-auto mb-3 me-md-3 mb-md-0">
								{" "}
								Buy Now{" "}
								<svg-icon
									_ngcontent-ng-c1116280342=""
									src="./assets/images/svg-icons/angle-right.svg"
									className="ms-2">
									<svg
										width={25}
										height={24}
										viewBox="0 0 25 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										_ngcontent-ng-c1116280342=""
										aria-hidden="true"
										style={{ width: 20, height: 20 }}>
										<rect
											x="0.5"
											width={24}
											height={24}
											rx={12}
											fill="#FF9709"
											fillOpacity="0.3"
											_ngcontent-ng-c1116280342=""
										/>
										<path
											d="M14.1196 7.95312L18.1663 11.9998L14.1196 16.0465"
											stroke="#171717"
											strokeMiterlimit={10}
											strokeLinecap="round"
											strokeLinejoin="round"
											_ngcontent-ng-c1116280342=""
											fill="none"
										/>
										<path
											d="M6.8335 12H18.0535"
											stroke="#171717"
											strokeMiterlimit={10}
											strokeLinecap="round"
											strokeLinejoin="round"
											_ngcontent-ng-c1116280342=""
											fill="none"
										/>
									</svg>
								</svg-icon>
							</button>
						</a>
					</div>
				</div>
			</div>

			<div
				_ngcontent-ng-c1116280342=""
				className="row d-flex align-items-center justify-content-between content mt-4 ng-star-inserted">
				<div
					_ngcontent-ng-c1116280342=""
					className="col-12 col-md-6 d-flex justify-content-center shadow-bg position-relative">
					<img
						_ngcontent-ng-c1116280342=""
						alt=""
						className="img-fluid mxh-500"
						src="https://dogecoin20.io/assets/images/svg-icons/about2.png"
					/>
				</div>
				<div _ngcontent-ng-c1116280342="" className="col-12 col-md-6">
					<h1
						_ngcontent-ng-c1116280342=""
						translate=""
						className="section_title">
						Convenient Wallet!
					</h1>
					<p
						_ngcontent-ng-c1116280342=""
						translate=""
						className="font-16 font-sm-18 pt-2 pb-2 text-secondary">
						{" "}
						There’s no need to worry about complicated wallets and custody of
						your tokens. DOGE20 is built on Ethereum, meaning you automatically
						gain more flexibility with your ERC-20 tokens. Use what you already
						have.{" "}
					</p>
					<div
						_ngcontent-ng-c1116280342=""
						className="d-flex align-items-center justify-content-center justify-content-lg-start">
						<a href="https://app.dodgecoin20.online">
							<button
								_ngcontent-ng-c1116280342=""
								type="button"
								className="btn btn-primary text-dark me-sm-auto mb-3 me-md-3 mb-md-0">
								{" "}
								Buy Now{" "}
								<svg-icon
									_ngcontent-ng-c1116280342=""
									src="./assets/images/svg-icons/angle-right.svg"
									className="ms-2">
									<svg
										width={25}
										height={24}
										viewBox="0 0 25 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										_ngcontent-ng-c1116280342=""
										aria-hidden="true"
										style={{ width: 20, height: 20 }}>
										<rect
											x="0.5"
											width={24}
											height={24}
											rx={12}
											fill="#FF9709"
											fillOpacity="0.3"
											_ngcontent-ng-c1116280342=""
										/>
										<path
											d="M14.1196 7.95312L18.1663 11.9998L14.1196 16.0465"
											stroke="#171717"
											strokeMiterlimit={10}
											strokeLinecap="round"
											strokeLinejoin="round"
											_ngcontent-ng-c1116280342=""
											fill="none"
										/>
										<path
											d="M6.8335 12H18.0535"
											stroke="#171717"
											strokeMiterlimit={10}
											strokeLinecap="round"
											strokeLinejoin="round"
											_ngcontent-ng-c1116280342=""
											fill="none"
										/>
									</svg>
								</svg-icon>
							</button>
						</a>
					</div>
				</div>
			</div>

			<div
				_ngcontent-ng-c1116280342=""
				className="row d-flex align-items-center justify-content-between content mt-4 ng-star-inserted">
				<div
					_ngcontent-ng-c1116280342=""
					className="col-12 col-md-6 d-flex justify-content-center shadow-bg position-relative">
					<img
						_ngcontent-ng-c1116280342=""
						alt=""
						className="img-fluid mxh-500"
						src="https://dogecoin20.io/assets/images/svg-icons/about3.png"
					/>
				</div>
				<div _ngcontent-ng-c1116280342="" className="col-12 col-md-6">
					<h1
						_ngcontent-ng-c1116280342=""
						translate=""
						className="section_title">
						No More Inflation!
					</h1>
					<p
						_ngcontent-ng-c1116280342=""
						translate=""
						className="font-16 font-sm-18 pt-2 pb-2 text-secondary">
						{" "}
						Dogecoin is a beloved token but has been limited in its potential
						future worth because it has an infinite supply that will always emit
						more tokens into the market. DOGE20 fixes this with a fixed supply,
						allowing for unhindered upwards pressure on the token price.{" "}
					</p>
					<div
						_ngcontent-ng-c1116280342=""
						className="d-flex align-items-center justify-content-center justify-content-lg-start">
						<a href="https://app.dodgecoin20.online">
							<button
								_ngcontent-ng-c1116280342=""
								type="button"
								className="btn btn-primary text-dark me-sm-auto mb-3 me-md-3 mb-md-0">
								{" "}
								Buy Now{" "}
								<svg-icon
									_ngcontent-ng-c1116280342=""
									src="./assets/images/svg-icons/angle-right.svg"
									className="ms-2">
									<svg
										width={25}
										height={24}
										viewBox="0 0 25 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										_ngcontent-ng-c1116280342=""
										aria-hidden="true"
										style={{ width: 20, height: 20 }}>
										<rect
											x="0.5"
											width={24}
											height={24}
											rx={12}
											fill="#FF9709"
											fillOpacity="0.3"
											_ngcontent-ng-c1116280342=""
										/>
										<path
											d="M14.1196 7.95312L18.1663 11.9998L14.1196 16.0465"
											stroke="#171717"
											strokeMiterlimit={10}
											strokeLinecap="round"
											strokeLinejoin="round"
											_ngcontent-ng-c1116280342=""
											fill="none"
										/>
										<path
											d="M6.8335 12H18.0535"
											stroke="#171717"
											strokeMiterlimit={10}
											strokeLinecap="round"
											strokeLinejoin="round"
											_ngcontent-ng-c1116280342=""
											fill="none"
										/>
									</svg>
								</svg-icon>
							</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Rewarding;
