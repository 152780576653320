import React from "react";
import Layout from "../../../layout/Layout";
import chart from "../../../assets/chart.png";

function Tokonomics() {
	return (
		<div>
			{/* Heading  */}
			<h2 className="hidden lg:block text-center text-neutral-900 text-5xl font-extrabold font-['Nunito Sans'] uppercase leading-[57.60px] tracking-wide">
				Tokenomics
			</h2>

			{/* MOBILE HEAD  */}
			<h2 className="lg:hidden text-center text-neutral-900 text-3xl font-extrabold font-['Nunito Sans'] uppercase leading-9 tracking-wide">
				Tokenomics
			</h2>
			{/* END OF HEADING  */}

			{/* Body  */}
			<Layout>
				<div className="py-10 grid grid-cols-1 lg:grid-cols-2 gap-10">
					<div className="relative w-full p-4 space-y-5 h-[367.55px] bg-white rounded-[20px] border-4 border-purple-600">
						<img
							src="https://dogecoin20.io/assets/images/svg-icons/badge.png"
							alt=""
							className="absolute w-10 h-10 -left-4 -top-4"
						/>
						<h3 className="text-center text-neutral-900 text-lg font-bold leading-snug">
							Token Information
						</h3>
						<div className="text-gray-500">
							<p>
								DOGE20 tokenomics include smart staking rewards, meaning you can
								earn right away.
							</p>
							<ul>
								<li>25% of supply sold in presale</li>
								<li>25% allocated to marketing</li>
								<li>25% for the project treasury</li>
								<li>15% used for staking rewards</li>
								<li>10% for exchange liquidity</li>
							</ul>
							<p className="">
								DOGE20 Token Address: 0x2541A36BE4cD39286ED61a3E6
								<br />
								AFC2307602489d6
							</p>
						</div>
					</div>

					<div className="flex flex-col lg:flex-row gap-5 items-center lg:items-end">
						<img src={chart} alt="" />
						<div>
							<div className="w-[110px] h-[326.97px] pb-4 flex-col justify-start items-start gap-4 hidden lg:inline-flex">
								<div className="w-[110px] pr-[36.94px] justify-start items-center inline-flex">
									<div className="w-6 h-2 pr-4 flex-col justify-start items-start inline-flex">
										<div className="w-2 h-2 bg-amber-300 rounded-lg" />
									</div>
									<div className="flex-col justify-start items-start gap-[0.01px] inline-flex">
										<div className="self-stretch h-4 pr-[4.09px] pb-[0.04px] flex-col justify-start items-start flex">
											<div className="text-purple-600 text-[13px] font-bold font-['Nunito Sans'] leading-none">
												Presale
											</div>
										</div>
										<div className="self-stretch h-[33.35px] pb-[0.35px] flex-col justify-start items-start flex">
											<div className="text-neutral-900 text-[23px] font-normal font-['Nunito Sans'] leading-loose">
												25%
											</div>
										</div>
									</div>
								</div>
								<div className="w-[110px] pr-[24.88px] justify-start items-center inline-flex">
									<div className="w-6 h-2 pr-4 flex-col justify-start items-start inline-flex">
										<div className="w-2 h-2 bg-purple-600 rounded-lg" />
									</div>
									<div className="flex-col justify-start items-start gap-[0.01px] inline-flex">
										<div className="self-stretch h-4 pb-[0.04px] flex-col justify-start items-start flex">
											<div className="text-purple-600 text-[13px] font-bold font-['Nunito Sans'] leading-none">
												Marketing
											</div>
										</div>
										<div className="self-stretch h-[33.35px] pr-[12.06px] pb-[0.35px] flex-col justify-start items-start flex">
											<div className="text-neutral-900 text-[23px] font-normal font-['Nunito Sans'] leading-loose">
												25%
											</div>
										</div>
									</div>
								</div>
								<div className="w-[110px] pr-[33.59px] justify-start items-center inline-flex">
									<div className="w-6 h-2 pr-4 flex-col justify-start items-start inline-flex">
										<div className="w-2 h-2 bg-indigo-300 rounded-lg" />
									</div>
									<div className="flex-col justify-start items-start inline-flex">
										<div className="self-stretch h-4 pb-[0.04px] flex-col justify-start items-start flex">
											<div className="text-purple-600 text-[13px] font-bold font-['Nunito Sans'] leading-none">
												Treasury
											</div>
										</div>
										<div className="self-stretch h-[33.35px] pr-[3.35px] pb-[0.35px] flex-col justify-start items-start flex">
											<div className="text-neutral-900 text-[23px] font-normal font-['Nunito Sans'] leading-loose">
												25%
											</div>
										</div>
									</div>
								</div>
								<div className="w-[110px] pr-[36.94px] justify-start items-center inline-flex">
									<div className="w-6 h-2 pr-4 flex-col justify-start items-start inline-flex">
										<div className="w-2 h-2 bg-lime-400 rounded-lg" />
									</div>
									<div className="flex-col justify-start items-start gap-[0px] inline-flex">
										<div className="self-stretch h-4 pr-[3.08px] pb-[0.04px] flex-col justify-start items-start flex">
											<div className="text-purple-600 text-[13px] font-bold font-['Nunito Sans'] leading-none">
												Staking
											</div>
										</div>
										<div className="self-stretch h-[33.35px] pb-[0.35px] flex-col justify-start items-start flex">
											<div className="text-neutral-900 text-[23px] font-normal font-['Nunito Sans'] leading-loose">
												15%
											</div>
										</div>
									</div>
								</div>
								<div className="w-[110px] pr-[33.69px] justify-start items-center inline-flex">
									<div className="w-6 h-2 pr-4 flex-col justify-start items-start inline-flex">
										<div className="w-2 h-2 bg-fuchsia-200 rounded-lg" />
									</div>
									<div className="flex-col justify-start items-start gap-[0px] inline-flex">
										<div className="self-stretch h-4 pb-[0.04px] flex-col justify-start items-start flex">
											<div className="text-purple-600 text-[13px] font-bold font-['Nunito Sans'] leading-none">
												Liquidity
											</div>
										</div>
										<div className="self-stretch h-[33.35px] pr-[3.25px] pb-[0.35px] flex-col justify-start items-start flex">
											<div className="text-neutral-900 text-[23px] font-normal font-['Nunito Sans'] leading-loose">
												10%
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="lg:hidden w-80 h-[191.34px] relative">
								<div className="w-[110px] h-[63.78px] left-[50px] top-0 absolute">
									<div className="pr-[44.94px] left-0 top-[-0.80px] absolute justify-start items-center inline-flex">
										<div className="w-4 h-2 pr-2 flex-col justify-start items-start inline-flex">
											<div className="w-2 h-2 bg-amber-300 rounded-lg" />
										</div>
										<div className="flex-col justify-start items-start inline-flex">
											<div className="self-stretch h-[16.79px] pr-[4.09px] pb-[0.79px] flex-col justify-start items-start flex">
												<div className="text-purple-600 text-[13px] font-bold font-['Nunito Sans'] leading-none">
													Presale
												</div>
											</div>
											<div className="self-stretch h-[33.59px] pb-[0.59px] flex-col justify-start items-start flex">
												<div className="text-neutral-900 text-[23px] font-normal font-['Nunito Sans'] leading-loose">
													25%
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="w-[110px] h-[63.78px] left-[160px] top-0 absolute">
									<div className="pr-[32.87px] left-0 top-[-0.80px] absolute justify-start items-center inline-flex">
										<div className="w-4 h-2 pr-2 flex-col justify-start items-start inline-flex">
											<div className="w-2 h-2 bg-purple-600 rounded-lg" />
										</div>
										<div className="flex-col justify-start items-start inline-flex">
											<div className="self-stretch h-[16.79px] pb-[0.79px] flex-col justify-start items-start flex">
												<div className="text-purple-600 text-[13px] font-bold font-['Nunito Sans'] leading-none">
													Marketing
												</div>
											</div>
											<div className="self-stretch h-[33.59px] pr-[12.07px] pb-[0.59px] flex-col justify-start items-start flex">
												<div className="text-neutral-900 text-[23px] font-normal font-['Nunito Sans'] leading-loose">
													25%
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="w-[110px] h-[63.78px] left-[50px] top-[63.78px] absolute">
									<div className="pr-[41.59px] left-0 top-[-0.80px] absolute justify-start items-center inline-flex">
										<div className="w-4 h-2 pr-2 flex-col justify-start items-start inline-flex">
											<div className="w-2 h-2 bg-indigo-300 rounded-lg" />
										</div>
										<div className="flex-col justify-start items-start inline-flex">
											<div className="self-stretch h-[16.79px] pb-[0.79px] flex-col justify-start items-start flex">
												<div className="text-purple-600 text-[13px] font-bold font-['Nunito Sans'] leading-none">
													Treasury
												</div>
											</div>
											<div className="self-stretch h-[33.59px] pr-[3.35px] pb-[0.59px] flex-col justify-start items-start flex">
												<div className="text-neutral-900 text-[23px] font-normal font-['Nunito Sans'] leading-loose">
													25%
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="w-[110px] h-[63.78px] left-[160px] top-[63.78px] absolute">
									<div className="pr-[44.94px] left-0 top-[-0.80px] absolute justify-start items-center inline-flex">
										<div className="w-4 h-2 pr-2 flex-col justify-start items-start inline-flex">
											<div className="w-2 h-2 bg-lime-400 rounded-lg" />
										</div>
										<div className="flex-col justify-start items-start inline-flex">
											<div className="self-stretch h-[16.79px] pr-[3.08px] pb-[0.79px] flex-col justify-start items-start flex">
												<div className="text-purple-600 text-[13px] font-bold font-['Nunito Sans'] leading-none">
													Staking
												</div>
											</div>
											<div className="self-stretch h-[33.59px] pb-[0.59px] flex-col justify-start items-start flex">
												<div className="text-neutral-900 text-[23px] font-normal font-['Nunito Sans'] leading-loose">
													15%
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="w-[110px] h-[63.78px] left-[105px] top-[127.57px] absolute">
									<div className="pr-[41.69px] left-0 top-[-0.80px] absolute justify-start items-center inline-flex">
										<div className="w-4 h-2 pr-2 flex-col justify-start items-start inline-flex">
											<div className="w-2 h-2 bg-fuchsia-200 rounded-lg" />
										</div>
										<div className="flex-col justify-start items-start inline-flex">
											<div className="self-stretch h-[16.79px] pb-[0.79px] flex-col justify-start items-start flex">
												<div className="text-purple-600 text-[13px] font-bold font-['Nunito Sans'] leading-none">
													Liquidity
												</div>
											</div>
											<div className="self-stretch h-[33.59px] pr-[3.25px] pb-[0.59px] flex-col justify-start items-start flex">
												<div className="text-neutral-900 text-[23px] font-normal font-['Nunito Sans'] leading-loose">
													10%
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</div>
	);
}

export default Tokonomics;
